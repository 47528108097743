import Navbar from 'react-bootstrap/Navbar';

import styles from './Footer.module.scss';

import SocialLinks from 'HiveClient/components/FooterContent/SocialLinks';
import ContactLinks from 'HiveClient/components/FooterContent/ContactLinks';
import LearnLinks from 'HiveClient/components/FooterContent/LearnLinks';
import Branding from 'HiveClient/components/FooterContent/Branding';
import Container from 'react-bootstrap/Container';
import { useFeatureFlagsContext } from '../../../../config/FeatureFlags/FeatureFlagsContext';
import { FeaturesEnum } from '../../../../config/FeatureFlags/FeaturesEnum';

export type Props = {
  preventNavigation: boolean;
};

const Footer = (props: Props) => {
  const { checkFeatureFlags } = useFeatureFlagsContext();

  return (
    <Navbar as={'footer'} variant={'dark'} bg={'dark'}>
      <Container fluid>
        <section
          className={[
            'tw-flex tw-w-full tw-flex-col-reverse tw-items-center md:tw-flex-row tw-p-4 tw-justify-between tw-gap-5',
            styles.footer,
          ].join(' ')}
        >
          <section
            className={[
              'tw-flex tw-flex-1 tw-gap-x-5 tw-max-w-xl',
              styles.mediaSection,
            ].join(' ')}
          >
            {!props.preventNavigation && (
              <SocialLinks
                className={[
                  styles.mediaLinks,
                  'tw-flex tw-flex-wrap tw-w-fit tw-justify-center tw-gap-4 tw-items-center',
                ].join(' ')}
              />
            )}
            {checkFeatureFlags(FeaturesEnum.FooterIcons) && (
              <>
                <section className={''}>
                  {!props.preventNavigation && (
                    <LearnLinks
                      className={[
                        styles.footerNav,
                        'tw-flex tw-flex-1 tw-flex-col tw-text-sm',
                      ].join(' ')}
                    />
                  )}
                </section>
                <section className={''}>
                  {!props.preventNavigation && (
                    <ContactLinks
                      className={[
                        styles.footerNav,
                        'tw-flex tw-flex-1 tw-flex-col tw-text-sm',
                      ].join(' ')}
                    />
                  )}
                </section>
              </>
            )}
          </section>
          <Branding
            className={[
              'tw-flex tw-flex-col tw-items-end max-md:tw-items-center',
            ].join(' ')}
          />
        </section>
      </Container>
    </Navbar>
  );
};

export default Footer;
