const supportedLanguages = [
  {
    id: '1',
    code: 'en',
    backendCode: 'en_US',
    name: 'English',
    localeOverride: 'en-GB',
  },
  {
    id: '2',
    code: 'ar-TN',
    backendCode: 'ar_TN',
    name: 'عربي',
    outputCalendar: 'gregory',
  },
];

export default supportedLanguages;
